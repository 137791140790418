import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import moment from 'moment'
import { rem } from 'polished'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import config from 'constants/config'
import { mediaHoverable } from 'lib/theme/mediaQueries'
import { fareTypes, fareCabins } from 'constants/flight'
import { useIntlDateFormatter } from 'lib/datetime/dateUtils'
import { INTL_DMY_CASUAL_SHORT_FORMAT } from 'constants/dateFormats'
import { OFFER_TRACKING_IN_VIEW_THRESHOLD } from 'constants/offerList'
import { EventDataKey } from 'home/pages/HomePage/useHomepageAnalytics'
import Group from 'components/utils/Group'
import Image from 'components/Common/Image'
import Clickable from 'components/Common/Clickable'
import PaletteSheet from 'components/Luxkit/PaletteSheet'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import BodyText from 'components/Luxkit/Typography/BodyText'
import PromotionLabel from 'components/Luxkit/Label/PromotionLabel'
import LineAwardIcon from 'components/Luxkit/Icons/line/LineAwardIcon'
import PriceRowPrice from 'components/Luxkit/PricePoints/PriceRowPrice'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import OfferUrgencyCountdownLabel from 'components/Common/Labels/OfferUrgencyCountdownLabel'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'

const FlightCardContainer = styled(Clickable)`
  display: grid;
  grid-template-rows: ${rem(155)} 1fr;
  position: relative;
  width: ${rem(275)};
  overflow: hidden;
  transition: background-color 0.2s;

  ${mediaHoverable} {
    &:hover {
      background-color: ${props => props.theme.palette.neutral.default.seven};
    }
  }
`

const DealContainer = styled(Group)`
  padding: ${rem(8)};
`

const ImageContainer = styled(PaletteSheet)`
  position: relative;
`

const CarrierContainer = styled.div`
  position: absolute;
  padding: ${rem(4)};
  background-color: ${props => props.theme.palette.neutral.default.eight};
  border: 1px solid ${(props) => props.theme.palette.neutral.default.five};
  border-radius: ${props => props.theme.borderRadius.S};
  bottom: ${rem(12)};
  left: ${rem(8)};
`

const CarrierImage = styled(Image)`
  max-width: ${rem(80)};
`

const LabelContainer = styled(LabelGroup)`
  position: absolute;
  top: ${rem(4)};
  left: ${rem(4)};
`

interface Props {
  flightDeal: App.FlightDeal;
  position?: number;
  hasMultipleAirlines: boolean;
}

function FlightDealImageTile(props: Props) {
  const { flightDeal, position, hasMultipleAirlines } = props
  const fareTypeLabel = fareTypes.find(type => type.value === flightDeal.fareType)?.label
  const fareClassLabel = fareCabins.find(type => type.value === flightDeal.fareClass)?.label
  const dispatchOfferListEvent = useContext(OfferListEventsContext)
  const dateFormatter = useIntlDateFormatter()

  const showFullDateLabel = useMemo(() => {
    const end = moment(flightDeal.salesEndDate)
    const leftToTheEnd = moment.duration(end.diff(moment()))
    const roundedEndDays = Math.round(leftToTheEnd.asDays())
    return roundedEndDays > 4
  }, [flightDeal.salesEndDate])

  const [inViewRef, hasBeenInView] = useInView({
    triggerOnce: true,
    threshold: OFFER_TRACKING_IN_VIEW_THRESHOLD,
  })

  useEffect(() => {
    if (hasBeenInView && position !== undefined) {
      dispatchOfferListEvent({
        type: OfferListEvents.productImpression,
        offer: flightDeal,
        position,
        key: EventDataKey.FlightImpression,
      })
    }
  }, [hasBeenInView, flightDeal, position, dispatchOfferListEvent])

  const onClick = useCallback(() => {
    if (position !== undefined) {
      dispatchOfferListEvent({
        type: OfferListEvents.productClick,
        offer: flightDeal,
        position,
        key: EventDataKey.FlightClick,
      })
    }
  }, [position, flightDeal, dispatchOfferListEvent])

  const hasCarrierLogo = flightDeal.carrier.logo?.id || flightDeal.carrier.logo?.url

  return (
    <FlightCardContainer
      to={`/flight/deal/${flightDeal.id}`}
      target={config.OPEN_NEW_TAB_OFFER_CLICK ? '_blank' : undefined}
      ref={inViewRef}
      onClick={onClick}
    >
      {flightDeal.heroImage?.id && (
        <ImageContainer paletteType="default">
          <Image
            aspectRatio="3:2"
            id={flightDeal.heroImage.id}
            fit="center"
            dpr={2}
            height="155"
            width="275"
          />
          <LabelContainer>
            <OfferUrgencyCountdownLabel endDate={flightDeal.salesEndDate} />
            {showFullDateLabel && <PromotionLabel variant="default" icon={<LineAwardIcon />}>
              Sale ends {dateFormatter(flightDeal.salesEndDate, INTL_DMY_CASUAL_SHORT_FORMAT)}
            </PromotionLabel>}
          </LabelContainer>
          {hasMultipleAirlines && hasCarrierLogo && (
            <CarrierContainer>
              <CarrierImage
                height={34}
                fit="center"
                image={flightDeal.carrier.logo}
                alt={`Logo of ${flightDeal.carrier.name}`}
              />
            </CarrierContainer>
          )}
        </ImageContainer>
      )}
      <DealContainer direction="vertical" gap={4}>
        <BodyText variant="large" weight="bold">{flightDeal.destinationName}</BodyText>
        <div>
          <PriceRowPriceCaption>{fareClassLabel} {fareTypeLabel} from</PriceRowPriceCaption>
          <PriceRowPrice
            size="L"
            price={flightDeal.perAdultPrice}
            saleUnit="adult"
          />
        </div>
      </DealContainer>
    </FlightCardContainer>
  )
}

export default FlightDealImageTile
